import React from "react";
import { graphql } from "gatsby";
import { get } from "lodash";

import queryString from "query-string";

import LienSupport from "../../content/support/investment";

const LienSupportPage = ({ data }) => {
	const faqs = convertNodes(get(data, "allContentfulPage"));
	const videos = convertNodes(get(data, "allContentfulHelpVideo")).map(
		video => ({
			...video,
			video: {
				id: queryString.parse(video.videoUrl.split("?")[1]).v,
				title: video.title
			}
		})
	);

	const lienFaqs = faqs.filter(f =>
		f.title
			.trim()
			.toLowerCase()
			.endsWith("investments")
	);

	const lienVideos = videos.filter(v =>
		v.title
			.trim()
			.toLowerCase()
			.endsWith("investments")
	);

	return <LienSupport {...{ videos: lienVideos, faqs: lienFaqs }} />;
};

function convertNodes(all) {
	const nodes = get(all, "edges");
	if (!nodes) return [];
	return nodes.map(n => n.node);
}

export default LienSupportPage;

export const pageQuery = graphql`
	query LienSupportQuery {
		allContentfulPage(
			filter: { pageType: { eq: "FAQ" } }
			sort: { fields: [title], order: ASC }
		) {
			edges {
				node {
					slug
					title
				}
			}
		}
		allContentfulHelpVideo {
			edges {
				node {
					id
					title
					videoUrl
				}
			}
		}
	}
`;
